import type { FullLocale } from '@/shared/locale'

interface FormatNumberProps {
  round?: boolean
  maximumFractionDigits?: number
  minimumFractionDigits?: number
}

export const formatNumber = (
  value: string | number,
  fullLocale: FullLocale,
  props?: FormatNumberProps,
) => {
  if (Number.isNaN(Number(value))) {
    return value
  }

  const config: FormatNumberProps = {
    round: props?.round,
    minimumFractionDigits:
      props?.minimumFractionDigits !== undefined
        ? props?.minimumFractionDigits
        : props?.round
          ? 0
          : 2,
    maximumFractionDigits:
      props?.maximumFractionDigits !== undefined ? props?.maximumFractionDigits : 2,
  }

  let numericValue = Number(value)
  numericValue = config.round ? Math.round(numericValue) : numericValue

  return new Intl.NumberFormat(fullLocale, {
    maximumFractionDigits: config.maximumFractionDigits,
    minimumFractionDigits: config.minimumFractionDigits,
  }).format(numericValue)
}

export const priceFormat = (
  price:
    | { priceInclVat: number; priceExclVat: number; priceUnit?: string | null }
    | { value: number; priceUnit?: string | null },
  fullLocale: FullLocale,
  isVatIncl?: boolean,
) => {
  const value = 'value' in price ? price.value : isVatIncl ? price.priceInclVat : price.priceExclVat
  const result = formatNumber(value, fullLocale)
  return !Number.isNaN(result) ? `${result} ${price.priceUnit}` : '-'
}
