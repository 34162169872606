'use client'

import { Box, spacing } from '@fortum/elemental-ui'

const DASHED_WIDTH = 10

type LineColorType = ColorProps<'line'>

export type DividerProps = {
  /**
   * Divider line color
   */
  color: LineColorType
  /**
   * Vertical margin
   */
  verticalSpacing: keyof typeof spacing
  /**
   * Divider line variant (dashed or solid for now)
   */
  variant: 'dashed' | 'solid'
  /**
   * Divider line height
   */
  height?: string
  /**
   * Dashed line spacing
   */
  dashSpacing?: number
}

const getBackgroundStyle = (
  { line: color }: LineColorType,
  variant: string,
  dashSpacing: number,
) => {
  switch (variant) {
    case 'dashed':
      return `repeating-linear-gradient(90deg, ${color}, ${color} 0 ${DASHED_WIDTH}px, transparent 0 ${
        DASHED_WIDTH + dashSpacing
      }px)`
    case 'solid':
      return color
    default:
      return color
  }
}

export const Divider = ({
  verticalSpacing,
  height = '1px',
  color,
  variant = 'dashed',
  dashSpacing = DASHED_WIDTH,
}: DividerProps) => (
  <Box
    mv={spacing[verticalSpacing]}
    height={height}
    style={{ background: getBackgroundStyle(color, variant, dashSpacing) }}
  />
)
