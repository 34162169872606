'use client'

import type { ReactNode } from 'react'
import React from 'react'

import { ContentText, spacing } from '@fortum/elemental-ui'

import { ListContextProvider } from '@/shared/components/BulletList'

export type BulletListProps = {
  /**
   * Array of text items for the list to present
   */
  children: ReactNode

  /**
   * Set custom color
   */
  colors?: ColorProps<never, 'textPrimary' | 'textPositive'>
}

/**
 * Component for simple bullet list presentation
 */
export const BulletList = ({ children, colors }: BulletListProps) => (
  <ListContextProvider
    value={{
      type: 'ul',
      iconColor: colors?.textPositive,
    }}
  >
    <ContentText
      tag="ul"
      data-testid="bullet-list"
      display="flex"
      flexDirection="column"
      gap={spacing.xxxs}
      textColor={colors?.textPrimary}
    >
      {children}
    </ContentText>
  </ListContextProvider>
)
