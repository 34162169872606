import type { CommonBoxProps } from '@fortum/elemental-ui'
import { Box, Gap, Popover, px2rem, spacing, usePopover } from '@fortum/elemental-ui'

import { priceFormat } from '@/open-web/utils/priceFormat'
import { useTheme } from '@/shared/hooks/useTheme'
import { useFullLocale } from '@/shared/locale'

export type ColorfulPriceBlockProps = {
  colors: ColorProps<'background', never>
  price: number
  priceUnit?: string
  label: string
  pricesSum: number
  boxProps: CommonBoxProps
}

export const ColorfulPriceBlock = ({
  colors,
  price,
  pricesSum,
  boxProps,
  label,
  priceUnit = '',
}: ColorfulPriceBlockProps) => {
  const fullLocale = useFullLocale()
  const { open: isOpened, anchor, handleOpen, handleClose } = usePopover()
  const theme = useTheme()
  const calculateBarWidth = () => `${(price / pricesSum) * 100}%`

  return (
    <Box
      backgroundColor={colors.background}
      height={px2rem(8)}
      width={calculateBarWidth()}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      {...boxProps}
    >
      <Popover
        opened={isOpened}
        anchor={anchor}
        anchorPos="top"
        backgroundColor={theme.colors.background}
        padding={spacing.xxxs}
        color={theme.colors.textSecondary}
        fontFamily={theme.font.family}
      >
        <Gap size={spacing.xxxs}>
          {label}
          <>{priceFormat({ priceExclVat: price, priceInclVat: price, priceUnit }, fullLocale)}</>
        </Gap>
      </Popover>
    </Box>
  )
}
