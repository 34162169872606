import type { PriceType } from '@/shared/graphql/schema/commonBackend/graphql'

export const FORBIDDEN_POA_PRICE_TYPES: PriceType[] = ['FIXED', 'HYBRID']

/**
 * First possible start date offset in days, for POA agreements
 */
export const START_DATE_OFFSET_POA = 5
/**
 * First possible start date offset in days, for NO POA agreements
 */
export const START_DATE_OFFSET_NO_POA = 16
/**
 * Last possible date which customer can pick as starting date is calculated base on current date plus this offset in months.
 */
export const SPOT_CONTRACT_LAST_POSSIBLE_DATE_MONTHS_OFFSET = 14
export const DATE_FORMAT = 'yyyy-MM-dd'
export const DATE_FORMAT_COMPARATIVE_PRICE = 'LLL yyyy'

export const GTM_CONTRACT_DEFAULT_MAIN_CATEGORY = 'power'
export const GTM_CONTRACT_DEFAULT_TERTIARY_CATEGORY = 'change_of_supplier'
