import type { CostDetails } from '../services/calculators/calculateContractTemplateCost'

export const getPriceBaseOnCustomerType = (price?: CostDetails, customerType?: string) => {
  if (!price) {
    return null
  }

  if (customerType?.toLocaleLowerCase() === 'enterprise') {
    return price?.priceExclVat
  }
  return price?.priceInclVat
}
