import type { FC, ReactNode } from 'react'
import styled from 'styled-components'

import { IconCheck, spacing } from '@fortum/elemental-ui'

import { useListContext } from '@/shared/components/BulletList/context'
import { Icon } from '@/shared/components/Icon'
import { useTheme } from '@/shared/hooks/useTheme'

type BulletListItemProps = {
  children: ReactNode
}

export const ListItem: FC<BulletListItemProps> = ({ children }) => {
  const theme = useTheme()
  const listContext = useListContext()
  const iconColor = listContext?.iconColor

  switch (listContext?.type) {
    case 'ul':
      return (
        <UlListItem>
          <Icon icon={IconCheck} color={iconColor || theme.colors.textPositive} />
          {children}
        </UlListItem>
      )

    case 'ol':
    default:
      return <li>{children}</li>
  }
}

const UlListItem = styled.li`
  display: flex;
  align-items: flex-start;
  gap: ${spacing.xxxs};

  & p {
    margin: 0;
  }
`
