'use client'

import { useTranslations } from 'next-intl'

import { Box, ContentText, px2rem, spacing } from '@fortum/elemental-ui'

import { useTheme } from '@/shared/hooks/useTheme'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'
import { useGlobalStore } from '@/shared/store/provider'
import type { CustomerType } from '@/shared/store/slices/selectedContractSlice'

import { Divider } from '../Divider'
import { StyledHeading } from '../StyledHeading'
import { ComparativePrice } from './parts/ComparativePrice'
import { PriceDetails } from './parts/PriceDetails'

type PriceBreakdownProps = {
  contractTemplate: EnrichedContractTemplate
}

/**
 * Used to present price estimated calculation details and comparison price details.
 */
export const PriceBreakdown = ({ contractTemplate }: PriceBreakdownProps) => {
  const t = useTranslations('purchaseFlow')
  const theme = useTheme()
  if (
    contractTemplate.customerType !== 'ENTERPRISE' &&
    contractTemplate.customerType !== 'PRIVATE'
  ) {
    throw new Error(
      `${contractTemplate.templateNo} has UNKNOWN customerType. Contract has bad configuration. Can not be sold!`,
    )
  }

  const customerType = contractTemplate.customerType.toLocaleLowerCase() as CustomerType
  const selectedContract = useGlobalStore((state) => state.selectedContract)
  const { selectedAddons } = selectedContract[customerType]

  //We don't want to present price breakdown for other contract types
  if (
    contractTemplate.priceType !== 'FIXED' &&
    contractTemplate.priceType !== 'HYBRID' &&
    contractTemplate.priceType !== 'SPOT'
  ) {
    return null
  }

  return (
    <Box display="flex" flexDirection="column" gap={spacing.m}>
      <Box display="flex" flexDirection="column" gap={spacing.xxs}>
        <StyledHeading textAlign="center" level={4}>
          {t('priceBreakdown.priceDetails.title')}
        </StyledHeading>
        <ContentText>
          {t(`priceBreakdown.priceDetails.description.${contractTemplate.priceType}`)}
        </ContentText>
        <PriceDetails contractTemplate={contractTemplate} selectedAddons={selectedAddons} />
      </Box>
      <Divider
        color={{ line: theme.colors.borderPrimary }}
        variant="solid"
        verticalSpacing="none"
        height={px2rem(2)}
      />
      <Box display="flex" flexDirection="column" gap={spacing.xxs}>
        <StyledHeading textAlign="center" level={4}>
          {t('priceBreakdown.comparisonPrice.title')}
        </StyledHeading>
        <ContentText>{t(`priceBreakdown.comparisonPrice.description`)}</ContentText>
        <ComparativePrice contractTemplate={contractTemplate} />
        <ContentText>
          {t('priceBreakdown.comparisonPrice.comparisonPriceSourceDescription')}
        </ContentText>
      </Box>
    </Box>
  )
}
