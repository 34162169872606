import { useTranslations } from 'next-intl'
import styled from 'styled-components'

import type { FontSize } from '@fortum/elemental-ui'
import { Box, ContentText, fontSizes, fontWeights, spacing } from '@fortum/elemental-ui'

export type PriceItemProps = {
  /**
   * Top label of the price section
   */
  priceLabel?: string | null
  /**
   * Actual price text
   */
  price: string
  /**
   * Pirce without discounts
   */
  originalPrice?: string
  /**
   * Description of the orgnial price eg.: Sale, Vat description
   */
  originalPriceDescription?: string
  /**
   * Definition if the price info is horizontally or vertically spaced
   */
  flexDirection?: 'row' | 'column'
  /**
   * text content alignment
   */
  alignContent?: 'left' | 'center' | 'right'
  /**
   * Font size of the main price
   */
  headingSize?: FontSize
  /**
   * Font size of the original price
   */
  priceStrikeThroughSize?: FontSize
  /**
   * Set custom color
   */
  colors?: ColorProps<never, 'textPrimary' | 'textPositive' | 'textSecondary'>
}

/**
 * Present prices of the contract up to 4 lines
 */
export const PriceItem = ({
  priceLabel,
  price,
  originalPrice,
  originalPriceDescription,
  flexDirection = 'column',
  alignContent = 'left',
  headingSize = 'xl',
  priceStrikeThroughSize = 'xs',
  colors,
}: PriceItemProps) => {
  const t = useTranslations('ariaLabels')
  return (
    <Box display="flex" flexDirection={flexDirection}>
      {priceLabel && (
        <ContentText
          size="s"
          textColor={colors?.textPrimary}
          mr={flexDirection === 'row' ? spacing.xxs : 0}
        >
          {priceLabel}
        </ContentText>
      )}
      <Box display="flex" flexDirection="column" alignItems={getAlignContent(alignContent)}>
        <ContentText
          textColor={colors?.textPrimary}
          size={headingSize}
          fontWeight={fontWeights.medium}
          textAlign={alignContent}
          aria-label={originalPrice ? t('discountPrice') : t('price')}
        >
          {price}
        </ContentText>
        {originalPrice && (
          <PriceStrikethrough
            color={colors?.textSecondary}
            size={priceStrikeThroughSize}
            aria-label={t('originalPrice')}
          >
            {originalPrice}
          </PriceStrikethrough>
        )}
        {originalPriceDescription && (
          <ContentText fontSize={fontSizes.xs} color={colors?.textPositive} textWrap="word">
            {originalPriceDescription}
          </ContentText>
        )}
      </Box>
    </Box>
  )
}

const getAlignContent = (alignContent: PriceItemProps['alignContent']) => {
  switch (alignContent) {
    case 'left':
      return 'flex-start'
    case 'center':
      return 'center'
    case 'right':
      return 'flex-end'
    default:
      return 'flex-start'
  }
}

const PriceStrikethrough = styled(ContentText)`
  text-decoration: line-through;
`
