import { useEffect, useState } from 'react'

import type {
  CalculateContractTemplateCostProps,
  ContractTemplateCost,
} from '../../services/calculators/calculateContractTemplateCost'
import { calculateContractTemplateCost } from '../../services/calculators/calculateContractTemplateCost'

export type UseContractTemplateCostCalculatorProps = CalculateContractTemplateCostProps

export const useContractTemplateCostCalculator = ({
  contractTemplate,
  selectedAddonsTariffNos,
  area,
  estimatedYearlyConsumption,
  startDate,
}: UseContractTemplateCostCalculatorProps) => {
  const [costsDetails, setCostsDetails] = useState<null | ContractTemplateCost>(null)

  useEffect(() => {
    const value = calculateContractTemplateCost({
      contractTemplate,
      selectedAddonsTariffNos,
      area,
      estimatedYearlyConsumption,
      startDate,
    })

    setCostsDetails(value)
  }, [contractTemplate, area, estimatedYearlyConsumption, startDate, selectedAddonsTariffNos])

  return costsDetails
}
