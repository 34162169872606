export const TARIFF_ELEMENT_TYPE_PREFIX = {
  energy: 'energy',
  monthlyFee: 'monthlyFee',
  discount: 'discount',
  discountEnergy: 'discount.energy',
  discountMonthlyFee: 'discount.monthlyFee',
}

export const isEnergyElement = (type: string) => type.startsWith(TARIFF_ELEMENT_TYPE_PREFIX.energy)
export const isEnergyDiscountElement = (type: string) =>
  type.startsWith(TARIFF_ELEMENT_TYPE_PREFIX.discountEnergy)
export const isMonthlyFeeElement = (type: string) =>
  type.startsWith(TARIFF_ELEMENT_TYPE_PREFIX.monthlyFee)
export const isMonthlyFeeDiscountElement = (type: string) =>
  type.startsWith(TARIFF_ELEMENT_TYPE_PREFIX.discountMonthlyFee)
export const isDiscountForElement = (type: string, discountType: string) =>
  type === discountType.replace(`${TARIFF_ELEMENT_TYPE_PREFIX.discount}.`, '')
