import type { CampaignConfigurationEntry } from '@/shared/contentful/types'

export const getActiveCampaignConfig = (
  campaignId?: number | null,
  campaignsConfigurations?: CampaignConfigurationEntry[],
) => {
  const campaignConfig = campaignsConfigurations?.find((c) => c?.campaignId === campaignId)
  if (campaignConfig) {
    return campaignConfig
  }
  throw new Error(`Can't find configuration for campaignId: ${campaignId}`)
}
