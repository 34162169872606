import { DateTime } from 'luxon'

import { DATE_FORMAT } from '@/open-web/utils/constants'
import type { AddonTemplate, PriceDetails } from '@/shared/graphql/queries/queryTypes'
import type { PriceAreaCode } from '@/shared/graphql/schema/commonBackend/graphql'
import type {
  EnrichedAvailableAddon,
  EnrichedBundledAddonTemplate,
  EnrichedContractTemplate,
} from '@/shared/services/campaignDataResolver'
import { sumPrices } from '@/shared/utils/contractTemplateUtils'
import { isNotNullOrUndefined } from '@/shared/utils/isNotNullOrUndefined'
import {
  isEnergyDiscountElement,
  isEnergyElement,
  isMonthlyFeeDiscountElement,
  isMonthlyFeeElement,
} from '@/shared/utils/tariffElementUtils'

import type { CostDetails, CostElement } from '../calculateContractTemplateCost'

type CalculationCostElement = Pick<CostDetails, 'priceExclVat' | 'priceInclVat' | 'vatAmount'>

export const DEFAULT_ACC = {
  energy: { priceInclVat: 0, priceExclVat: 0, vatAmount: 0 },
  monthlyFee: { priceInclVat: 0, priceExclVat: 0, vatAmount: 0 },
}

export const MONTHS_IN_YEAR = 12

const sum = (a: number, b: number) => a + b

//date in DATE_FORMAT
export const getValidPriceForDate = (date: string, prices?: PriceDetails[] | null) => {
  const formattedDate = DateTime.fromFormat(date, DATE_FORMAT)
  return prices?.find(
    (price) =>
      DateTime.fromISO(price.fromDate) <= formattedDate &&
      price.toDate &&
      DateTime.fromISO(price.toDate) > formattedDate,
  )
}

export const calcPriceObjects = (
  priceA: CalculationCostElement,
  priceB: CalculationCostElement,
  calc: (valA: number, valB: number) => number,
) => {
  const keys = Object.keys(priceA) as (keyof CalculationCostElement)[]
  return keys.reduce<CalculationCostElement>(
    (acc, key) => ({ [key]: calc(priceA[key], priceB[key]), ...acc }),
    {} as CalculationCostElement,
  )
}

export const sumPricesByType = (
  contractTemplate: EnrichedContractTemplate,
  addonTemplates: (EnrichedAvailableAddon | EnrichedBundledAddonTemplate)[],
  startDate: string,
  area: PriceAreaCode,
  elementTypeIndicator: (item: string) => boolean,
): CostDetails => {
  const addonsTariffElements = addonTemplates.flatMap((addon) =>
    addon?.tariffElements?.filter((el) => elementTypeIndicator(el.type)),
  )

  const contractTariffElements =
    contractTemplate?.tariffElements.filter((tariffEl) => elementTypeIndicator(tariffEl.type)) || []

  const priceUnit = contractTariffElements[0].priceUnit

  if (!priceUnit) {
    throw new Error('priceUnit can not be undefined or empty string')
  }

  const priceDetails = [...addonsTariffElements, ...contractTariffElements]
    .flatMap((item) => item?.prices || getValidPriceForDate(startDate, item?.pricesByArea?.[area]))
    .filter(isNotNullOrUndefined)

  return {
    priceInclVat: sumPrices(priceDetails, 'priceInclVat'),
    priceExclVat: sumPrices(priceDetails, 'priceExclVat'),
    vatAmount: sumPrices(priceDetails, 'vatAmount'),
    priceUnit,
  }
}

export const getAddonsMonthlyDiscounts = (addons: AddonTemplate[]): CostElement => {
  const priceElement = addons.reduce((acc, addon) => {
    const { discountElements = [] } = addon

    const result = discountElements?.reduce((elementsSum, discountEl) => {
      const price = discountEl?.prices?.[0]

      if (!price) {
        return elementsSum
      }

      if (isEnergyDiscountElement(discountEl.type)) {
        return {
          energy: calcPriceObjects(price, elementsSum.energy, sum),
          monthlyFee: elementsSum.monthlyFee,
        }
      }

      return {
        energy: elementsSum.energy,
        monthlyFee: calcPriceObjects(price, elementsSum.monthlyFee, sum),
      }
    }, DEFAULT_ACC)
    return result
      ? {
          energy: calcPriceObjects(acc.energy, result?.energy, sum),
          monthlyFee: calcPriceObjects(acc.monthlyFee, result?.monthlyFee, sum),
        }
      : DEFAULT_ACC
  }, DEFAULT_ACC)

  const energyPriceUnit = addons
    .flatMap((addon) => addon.tariffElements)
    .filter(isNotNullOrUndefined)
    ?.find((t) => isEnergyElement(t.type))?.priceUnit

  const monthlyPriceUnit = addons
    .flatMap((addon) => addon.tariffElements)
    .filter(isNotNullOrUndefined)
    ?.find((t) => isMonthlyFeeElement(t.type))?.priceUnit

  return {
    energy: { ...priceElement.energy, priceUnit: energyPriceUnit },
    monthlyFee: { ...priceElement.monthlyFee, priceUnit: monthlyPriceUnit },
  }
}

/**
 * Returned discount value is spread over year. That means if we get a discount for 6 months for 20 kr/man.
 * Discount value is by default spread over year so it will be 10 kr/man = (discount_duration * discount_price) / spreadOverTime
 * By default spreadOverTime is set to be MONTHS_IN_YEAR - 12
 */
export const getContractMonthlyDiscounts = (
  contractTemplate: EnrichedContractTemplate,
  spreadOverTime: number = MONTHS_IN_YEAR,
): CostElement => {
  const priceElement = contractTemplate?.discountElements.reduce((acc, discountEl) => {
    const price = discountEl.prices?.[0]

    if (!price) {
      return acc
    }

    // If discounts is for 6 months it means that half of the discount value should be included
    // discountDurationOverYear = duration / monthsInYear
    // If discountDurationOverYear is 1 means that discount is applied for entire year
    const discountDurationOverYear = discountEl.duration
      ? Math.min(discountEl.duration.amount || spreadOverTime, spreadOverTime) / spreadOverTime
      : undefined

    if (isEnergyDiscountElement(discountEl.type)) {
      return {
        energy: calcPriceObjects(
          acc.energy,
          price,
          (valA, valB) =>
            valA + (discountDurationOverYear ? discountDurationOverYear * valB : valB),
        ),
        monthlyFee: acc.monthlyFee,
      }
    }

    return {
      energy: acc.energy,
      monthlyFee: calcPriceObjects(
        acc.monthlyFee,
        price,
        (valA, valB) => valA + (discountDurationOverYear ? discountDurationOverYear * valB : valB),
      ),
    }
  }, DEFAULT_ACC)

  const energyPriceUnit = contractTemplate.discountElements.find((discount) =>
    isEnergyDiscountElement(discount.type),
  )?.priceUnit

  const monthlyPriceUnit = contractTemplate.discountElements.find((discount) =>
    isMonthlyFeeDiscountElement(discount.type),
  )?.priceUnit

  return {
    energy: { ...priceElement.energy, priceUnit: energyPriceUnit },
    monthlyFee: { ...priceElement.monthlyFee, priceUnit: monthlyPriceUnit },
  }
}

export const calcEstimatedMonthlyCost = (
  estimatedYearlyConsumption: number,
  energy: CostDetails,
  monthlyFee: CostDetails,
  contractTemplateDiscount?: CostElement,
  addonsDiscounts?: CostElement,
): CostDetails => {
  const calc = (
    name: keyof CalculationCostElement,
    energyPrice: CostDetails,
    monthlyFee: CostDetails,
    contractTemplateDiscount?: CostElement,
    addonsDiscounts?: CostElement,
  ) => {
    // We assume to always get energy prices in cents. So we need to use baseNumber to get same units as monthlyFee
    const baseNumber = 100

    const energyPriceWithDiscounts =
      energyPrice[name] +
      (contractTemplateDiscount?.energy?.[name] || 0) +
      (addonsDiscounts?.energy?.[name] || 0)

    const energyTotalCost =
      (estimatedYearlyConsumption * energyPriceWithDiscounts) / baseNumber / MONTHS_IN_YEAR

    const monthlyFeeTotalCost =
      monthlyFee[name] +
      (contractTemplateDiscount?.monthlyFee?.[name] || 0) +
      (addonsDiscounts?.monthlyFee?.[name] || 0)

    return energyTotalCost + monthlyFeeTotalCost
  }

  return {
    priceInclVat: calc(
      'priceInclVat',
      energy,
      monthlyFee,
      contractTemplateDiscount,
      addonsDiscounts,
    ),
    priceExclVat: calc(
      'priceExclVat',
      energy,
      monthlyFee,
      contractTemplateDiscount,
      addonsDiscounts,
    ),
    vatAmount: calc('vatAmount', energy, monthlyFee, contractTemplateDiscount, addonsDiscounts),
    priceUnit: monthlyFee.priceUnit,
  }
}

export const getEnergyPriceSumWithDiscounts = (
  energyPriceSum: CostDetails,
  addonsMonthlyDiscountsEnergy: CostDetails,
  contractMonthlyDiscountsEnergy: CostDetails,
): CostDetails => {
  const sum = (valA: number, valB: number) => valA + valB

  const discounts = calcPriceObjects(
    addonsMonthlyDiscountsEnergy,
    contractMonthlyDiscountsEnergy,
    sum,
  )

  return {
    ...calcPriceObjects(energyPriceSum, discounts, sum),
    priceUnit: energyPriceSum.priceUnit,
  }
}
