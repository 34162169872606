import { usePathname, useRouter } from '@/shared/navigation'
import { routes } from '@/shared/routes'

export const usePurchaseFlowNavigation = () => {
  const router = useRouter()
  const pathname = usePathname()

  const navigateNextPage = (route: string, enterpriseRoute: string) => {
    router.push(pathname.startsWith(routes.ENTERPRISE_HOME) ? enterpriseRoute : route)
  }

  return navigateNextPage
}
