import { createContext, useContext } from 'react'

export type ListItemType = 'ul' | 'ol'

type ListContext = {
  type: ListItemType

  /**
   * Set color for icon
   */
  iconColor?: string
}

const context = createContext<ListContext | undefined>({ type: 'ol' })

export const ListContextProvider = context.Provider
export const useListContext = () => useContext(context)
