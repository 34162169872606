'use client'

import { useTranslations } from 'next-intl'
import { type ReactNode, useState } from 'react'
import styled from 'styled-components'

import { Box, ButtonPill, IconInfo, Modal, spacing } from '@fortum/elemental-ui'
import type { CommonBoxProps } from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'
import type { CampaignConfigurationEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'
import type {
  EnrichedContractProduct,
  EnrichedContractTemplate,
} from '@/shared/services/campaignDataResolver'
import { getHoverColor } from '@/shared/utils/colors'

import { PriceBreakdown } from '../PriceBreakdown/PriceBreakdown'
import { PurchaseButton } from '../PurchaseButton'
import { StyledLink } from '../StyledLink'

export type PriceBreakdownInfoIconProps = {
  /**
   * Price and marketing information about contract.
   */
  contractTemplate: EnrichedContractTemplate
  /**
   * Contract templates group.
   */
  contractProduct: EnrichedContractProduct

  /**
   * Campaign configuration of given contractTemplate.
   */
  campaignConfiguration: CampaignConfigurationEntry
  /**
   * Any react node which should be rendered in flexbox on the left side of the icon.
   * Provided element after click will trigger modal.
   */
  children?: ReactNode
  boxProps?: CommonBoxProps
  iconSize?: number
  /**
   * Mode for Modal
   * default or undefined - only PriceBreakdown component is presented inside the modal
   * withFooter - two buttons on the bottom of the footer (purchase contract button and read more button)
   */
  mode?: 'default' | 'withFooter'
  theme?: 'light' | 'dark'
  /**
   * Disable price breakdown link button
   */
  isDisabled?: boolean
}

/**
 * Info icon used to open PriceBreakdown component inside the modal.
 */
export const PriceBreakdownInfoIcon = ({
  children,
  contractTemplate,
  campaignConfiguration,
  contractProduct,
  boxProps,
  iconSize,
  mode = 'default',
  theme = 'light',
  isDisabled,
}: PriceBreakdownInfoIconProps) => {
  const { colors } = useTheme()
  const t = useTranslations('purchaseFlow.priceBreakdown')
  const [isOpened, setModalOpened] = useState(false)
  const color = theme === 'light' ? colors.textLink : colors.textLightOnDark

  return (
    <>
      <UnderlineButton
        onClick={() => setModalOpened(true)}
        $color={color}
        data-testid="price-breakdown-info-button"
        aria-label={t('comparisonPrice.priceBreakdownInfoButtonAriaLabel')}
        $isDisabled={isDisabled}
      >
        <Box display="flex" alignItems="center" gap={spacing.xxxxs} color="inherit" {...boxProps}>
          {children}
          <Icon style={{ color: 'inherit' }} icon={IconInfo} size={iconSize ?? 20} />
        </Box>
      </UnderlineButton>

      <Modal
        opened={isOpened}
        onClose={() => setModalOpened(false)}
        alignContent="left"
        variant="condensed"
      >
        <PriceBreakdown contractTemplate={contractTemplate} />
        {mode === 'withFooter' && (
          <Box
            mt={spacing.xxl}
            display="flex"
            flexDirection={{ default: 'column', l: 'row' }}
            justifyContent="center"
            alignItems="center"
            gap={spacing.xs}
          >
            <PurchaseButton
              contractTemplate={contractTemplate}
              contractProduct={contractProduct}
              campaignConfiguration={campaignConfiguration}
            />
            {contractProduct.cfData.url && (
              <StyledLink
                href={contractProduct.cfData.url}
                data-testid="more-info-about-product-link"
              >
                <ButtonPill status="secondary">
                  {t('modalSecondaryButtonText', { contractName: contractProduct.cfData.title })}
                </ButtonPill>
              </StyledLink>
            )}
          </Box>
        )}
      </Modal>
    </>
  )
}

const UnderlineButton = styled.button<{ $color: string; $isDisabled?: boolean }>`
  color: ${({ $color }) => $color};
  border: none;
  background: none;
  text-decoration: underline;
  font-family: ${({ theme }) => theme.font.family};
  ${({ $isDisabled }) => $isDisabled && 'pointer-events: none'};

  &:hover {
    color: ${({ theme, $color }) => getHoverColor(theme, $color)};
    text-decoration: ${({ theme, $color }) => `underline solid ${getHoverColor(theme, $color)}`};
  }
`
